import { Component, OnInit } from '@angular/core';
import { ModaleComponent } from '../modale/modale.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageFormComponent } from '../message-form/message-form.component';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.scss'],
})
export class MainListComponent implements OnInit {
  constructor(public dialog: MatDialog, private router: Router) {}
  animal;
  slides = [
    {
      image: '../assets/icons8-email-aperta-64.png',
    },
    {
      image: '../assets/icons8-email-aperta-64.png',
    },
    {
      image: '../assets/icons8-email-aperta-64.png',
    },
    {
      image: '../assets/icons8-email-aperta-64.png',
    },
    {
      image: '../assets/icons8-email-aperta-64.png',
    },
  ];
  isMobile() {
    return GeneralService.isMobile();
  }
  isTablet() {
    return GeneralService.isTablet();
  }
  isDesktop() {
    return GeneralService.isDesktop();
  }
  openDialog(title): void {
    const dialogRef = this.dialog.open(ModaleComponent, {
      width: '900px',
      height: this.isMobile()
        ? this.macroInfo[title].heightMobile
        : this.macroInfo[title].height,
      data: {
        array: this.macroInfo[title].array,
        title: title,
        text: this.macroInfo[title].text,
        textHtml: this.macroInfo[title].textHtml,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.animal = result;
    });
  }
  openDialogMessage(title): void {
    const dialogRef = this.dialog.open(MessageFormComponent, {
      width: '1100px',
      data: {
        array: this.macroInfo[title].array,
        title: title,
        text: this.macroInfo[title].text,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.animal = result;
    });
  }
  redirect(t) {
    this.router.navigate(['' + t + '']);
  }
  macroInfo = {
    Autoanalisi: {
      height: '800px',
      heightMobile: '500px',
      array: [
        {
          titolo: 'Analisi',
          paragrafo:
            '<div> Presso la Farmacia Donato di Torino ci si può sottoporre alle seguenti analisi ematologiche e di tipo diagnostico: <br /><br /> <b>Autoanalisi Creatinina</b> - Sistema di autoanalisi per la misurazione quantitativa della Creatinina ed eGFR, in sangue intero capillare ottenuto da polpastrello, a fini di screening della salute dei reni in soggetti asintomatici con potenziali disturbi renali quali la nefropatia cronica e l’insufficienza renale acuta, mediante il calcolo del tasso presunto di filtrazione glomerulare (eGFR). <b>10,00€</b> <hr /> <b>Pannello lipidico completo</b> - colesterolo totale (concentrazione del colesterolo nel sangue), HDL (colesterolo buono), LDL (colesterolo nocivo) e Trigliceridi (concentrazione dei grassi nel sangue) <b>16,00€</b> <hr /> <b>Glicemia</b> – concentrazione del glucosio nel sangue <b>6,00 €</b> <hr /> <b>Emoglobina glicata</b> – valori di concentrazione media del glucosio libero nel sangue. Per la riuscita dell’esame è necessario presentarsi a digiuno. L’esame ha un prezzo di <b>12€</b> ed in pochi minuti si ha il risultato. <hr /> <b>Misurazione Pressione</b> - Il servizio è gratuito e prevede la misurazione della pressione sistolica (massima), diastolica (minima) e delle pulsazioni cardiache. <hr /> <b>Controllo del Peso</b> - Il servizio è gratuito </div>',
        },
      ],
      textHtml:
        '<div>Detta anche “Autoanalisi di primo livello”, è uno dei servizi più importanti che la vostra Farmacia di fiducia vi può offrire: si tratta di autoanalisi effettuate direttamente in farmacia. I vantaggi che si possono ottenere rispetto ai classici metodi diagnostici sono molteplici. Innanzitutto la rapidità: niente lunghe code e prenotazioni, velocità nell’esecuzione dell’analisi e in pochi minuti potrete ottenere un risultato attendibile per il vostro medico curante.</br>La precisione dello strumento è data da parametri di riferimento periodicamente controllati dal farmacista.</br>Le analisi sono eseguite professionalmente e garantiscono un risultato preciso, immediato ed affidabile.</div>',
    },
    'Altri servizi': {
      height: '800px',
      heightMobile: '500px',
      array: [
        

        {
          titolo: 'ECG',
          paragrafo:
            '<div>In Farmacia è possibile eseguire l’ECG grazie ad un servizio di TELEMEDICINA. <br></br> E’ preferibile prendere appuntamento, salvo situazioni di emergenza in cui si procede nel più breve tempo possibile. Sarà richiesto al paziente di compilare un “CONSENSO INFORMATO” al trattamento dei dati personali, con notizie riguardanti l’ANAMNESI e i possibili fattori di rischio. L’esecuzione dell’esame a minori, richiede la presenza di un genitore o di chi ne fa le veci. L’ECG è la riproduzione grafica dell’attività elettrica del cuore durante il suo funzionamento, registrata a livello della superficie del corpo. In pratica vengono applicati 10 elettrodi: 6 nel torace attorno alla regione cardiaca e 4 alle estremità degli arti, dopo aver sgrassato la cute con un solvente. In caso di eccessiva peluria si richiede al paziente una preventiva rasatura. Si collegano quindi i cavi a ciascun elettrodo e si avvia la registrazione che avrà la durata di 60 secondi; la refertazione, effettuata dal cardiologo dopo la trasmissione al centro servizi, può essere disponibile dopo circa un’ora o, in urgenza, entro pochi minuti con un piccolo costo aggiuntivo. L’ECG è un esame di base semplice e sicuro, usato in numerosissimi contesti clinici: come controllo primario nei soggetti a rischio (diabetici, ipertesi, dislipidemici),come monitoraggio dopo eventi patologici, per misurare la frequenza ed il ritmo dei battiti cardiaci, per individuare possibili danni al muscolo cardiaco o al sistema di conduzione, per controllare gli effetti indotti da farmaci o verificare la funzionalità di un pacemaker. Spesso, infine, è richiesto dal medico di medicina generale per la compilazione del certificato medico sportivo per attività non agonistica.</div>',
        },
        // {
        //   titolo: 'HOLTER PRESSORIO',
        //   paragrafo:
        //     '<div>Presso la nostra Farmacia è possibile effettuare, grazie ad un servizio di TELEMEDICINA, l’HOLTER PRESSORIO ossia il monitoraggio della pressione arteriosa registrata continuativamente nelle 24 ore. Sarà richiesto al paziente di compilare un “CONSENSO INFORMATO” al trattamento dei dati personali, con notizie riguardanti l’ANAMNESI e i possibili FATTORI DI RISCHIO. Per poter installare l’apparecchio, è opportuno vestirsi con abiti non aderenti, che permettano di nascondere un piccolo apparecchio, delle dimensioni di un walkman, necessario per la rilevazione dei dati, che verrà fissato in cintura; inoltre verrà posizionato nel braccio sinistro un manicotto. Il paziente li dovrà indossare per 24 ore e dovrà annotare su un foglio ogni dato utile, per esempio l’attività svolta, uno stato di stress emotivo, dei sintomi o disturbi accusati. Durante il periodo dell’esame, la pressione verrà misurata automaticamente ogni 15 minuti di giorno e ogni 30 minuti di notte. Trascorse le 24 ore, il paziente deve ripresentarsi in farmacia per consentire al farmacista di smontare l’apparecchio ed inviare i dati registrati al centro servizi; qui verranno analizzati e valutati dal Cardiologo. Il REFERTO, comprensivo delle misurazioni effettuate, di elaborazioni grafiche e del commento dello specialista, verrà consegnato dalla Farmacia al paziente il giorno successivo. Il monitoraggio pressorio delle 24 ore, rispetto alla normale rilevazione della pressione arteriosa, si dimostra particolarmente utile in tutti quei pazienti che manifestano valori molto variabili, con sbalzi improvvisi o sintomi, quali vertigini, sbandamenti, vampate, senso di svenimento, che compaiono in vari momenti della giornata.</div>',
        // },
        {
          titolo: 'Tamponi',
          paragrafo:
            "<div>E' possibile effettuare un tampone rapido nella nostra sede al prezzo di <b>15€</b></div>",
        },
        {
          titolo: 'Foratura lobi',
          paragrafo:
            "<div>I MINORI di 18 anni devono essere accompagnati da un genitore. Il foro viene praticato SOLO nel LOBO e nelle CARTILAGINI dell'orecchio e non in altre parti del corpo.<br> Il COSTO della foratura, comprensivo di orecchini, è di <b>25,00€</b></div>",
        },
        {
          titolo: 'Consegna a dominicilio - Servizio gratuito',
          paragrafo:
            'Per la consegna dei medicinali urgenti a domicilio su prescrizione medica e/o di libera vendita è necessario contattare telefonicamente, via whatsapp al numero 0114032610 o via email la farmacia.',
        },
        {
          titolo: 'Prenotazione visite mediche',
          paragrafo:
            'È possibile prenotare visite mediche e ritirare i referti tramite Sovracup. <br>Sono esclusi dal servizio gli esami ad accesso diretto (es. esami del sangue e delle urine). <br>Per prenotare è necessario contattare telefonicamente, via whatsapp al numero 0114032610 o via email la farmacia.',
        },
        // {
        //   titolo: 'Tamponi',
        //   paragrafo:
        //     "<div>E' possibile effettuare un tampone rapido nella nostra sede al prezzo di <b>15€</b></div><div>Il prezzo è di <b>8€</b> per la fascia di età compresa tra i 12 anni (compiuti) e i 18 anni (da compiere)</div>",
        // },
      ],

      textHtml:
        "<div>Questa sezione è dedicata ai servizi che la farmacia offre in questo periodo dell'anno.<br> Consulta questa sezione per conoscere le promozioni del mese e i servizi extra attivi.</div> ",
    },
    Cosmetica: {
      height: '800px',
      heightMobile: '500px',
      array: [
        {
          titolo: 'Consegna a dominicilio',
          paragrafo:
            'Per la consegna dei medicinali urgenti a domicilio è necessario contattare telefonicamente o via email la farmacia',
        },
        {
          titolo: 'Prenotazione visite mediche',
          paragrafo:
            '<div>È possibile prenotare visite mediche e ritirare i referti tramite Sovracup.</div><div> Sono esclusi dal servizio gli esami ad accesso diretto (es. esami del sangue e delle urine).</div>',
        },
        {
          titolo: 'Consegna a domicilio',
          paragrafo:
            'Per la consegna dei medicinali urgenti a domicilio è necessario prendere accordi in base alla possibilità d’impiego del personale.',
        },
      ],

      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    Laboratorio: {
      height: '480px',
      array: [
        // {
        //   titolo: 'uno',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
      ],
      textHtml:
        'Capsule, gocce, sciroppi e creme personalizzate? Rivolgiti al nostro Laboratorio!<br> Eseguiamo preparazioni galeniche officinali, in conformità alle Farmacopee Europee, e magistrali, su prescrizione medica. <br> Il laboratorio, separato dal resto della farmacia, garantisce qualità e sicurezza sull’allestimento di tali preparazioni. <br>Ci avvaliamo dell’utilizzo di macchinari specifici come cappe di aspirazione e filtrazione, incapsulatrici manuali e semiautomatiche, turboemulsori, miscelatori automatici, agitatori elettromagnetici autoriscaldanti.<br>Per preventivi, ordini e disponibilità delle sostanze contattaci al nostro indirizzo laboratorio@farmaciadonato.com o al numero 0114032610.',
    },
    'Tessera punti': {
      height: '230px',
      array: [
        // {
        //   titolo: 'uno',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'due',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
        // {
        //   titolo: 'tre',
        //   paragrafo:
        //     'mnscbjaksbcaks csklnlksnclknscksnaclknadlv skndlskanclkansvalnkvlkadnvklandvlknadlkvnalkdnvlkanvklanvlknalkvnalksvnlkanvlkavnlakvn',
        // },
      ],
      text: 'La Tua Farmacia ti premia!    La card viene rilasciata gratuitamente e senza alcun vincolo. Informativa sulla Privacy ',
    },
    Tirocinio: {
      height: '400px',
      array: [
        {
          titolo: 'Tirocini curricolari',
          paragrafo:
            'si intendono sia stage formativi per gli studenti delle scuole superiori sia tirocini obbligatori durante il percorso formativo di laurea per i corsi di Farmacia, Chimica e Tecnologia Farmaceutiche, Tecniche Erboristiche',
        },
        {
          titolo: 'Tirocini extracurricolari',
          paragrafo:
            'Entro e non oltre i 12 mesi dal conseguimento della laurea.',
        },
      ],
      text: 'Per ulteriori informazioni contattateci all’indirizzoinfo@farmaciadonato.com o al numero 011/4032610.',
    },
  };
  tiles: any[] = [
    // {text: 'One', cols: 4, rows: 2, color: 'lightblue'},
    // {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    // {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    // {text: 'Four', cols: 4, rows: 1, color: '#DDBDF1'},
    {
      destinatari: 'info@farmaciadonato.com',
      body: 'Inserire il contenuto della richiesta di seguito',
      height: '100%',
      objfit: true,
      subject: 'Farmacia Donato: Servizi principali',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
      text: 'Autoanalisi',
      colMobile: '12',
      colTablet: '12',
      colDesktop: '6',
      rowMobile: '6',
      rowTablet: '6',
      rowDesktop: '6',

      rows: 5,
      color: 'white',
      radius: '1',
      img: 'FARMACIA DONATO_TORINO_hd-56.jpg',
    },
    {
      destinatari: 'info@farmaciadonato.com',
      body: 'Inserire il contenuto della richiesta di seguito',
      height: '100%',
      objfit: false,
      subject: 'Farmacia Donato: Servizi principali',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
      text: 'Altri servizi',
      colMobile: '12',
      colTablet: '12',
      colDesktop: '6',
      rowMobile: '6',
      rowTablet: '6',
      rowDesktop: '6',
      rows: 5,
      color: 'white',
      radius: '1',
      img: 'ecgFoto.png',
    },

    {
      destinatari: 'info@farmaciadonato.com',
      body: 'Inserire il contenuto della richiesta di seguito',
      height: '100%',
      objfit: true,
      subject: 'Farmacia Donato: Servizi principali',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
      text: 'Laboratorio',
      colMobile: '12',
      colTablet: '12',
      colDesktop: '6',
      rowMobile: '6',
      rowTablet: '6',
      rowDesktop: '6',
      rows: 5,
      color: 'white',
      radius: '1',
      img: 'FARMACIA DONATO_TORINO_hd-54.jpg',
    },
    {
      destinatari: 'info@farmaciadonato.com',
      body: 'Inserire il contenuto della richiesta di seguito',
      height: '100%',
      objfit: true,
      subject: 'Farmacia Donato: Servizi principali',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
      text: 'Cosmetica',
      colMobile: '12',
      colTablet: '12',
      colDesktop: '6',
      rowMobile: '6',
      rowTablet: '6',
      rowDesktop: '6',
      rows: 5,
      color: 'white',
      radius: '1',
      img: 'FARMACIA DONATO_TORINO_hd-41.jpg',
    },
    // {
    //   destinatari:
    //     'info@farmaciadonato.com',
    //   body: 'Inserire il contenuto della richiesta di seguito',
    //   height: '100%',
    //   objfit: true,
    //   subject: 'Farmacia Donato: Servizi principali',
    //   description:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
    //   text: 'Tirocinio',
    //   colMobile: '12',
    //   colTablet: '12',
    //   colDesktop: '12',
    //   rowMobile: '3',
    //   rowTablet: '3',
    //   rowDesktop: '3',
    //   rows: 5,
    //   color: 'white',
    //   radius: '1',
    //   img: 'tirocinio_clicca.png',
    // },
  ];
  redirectCard(redirect) {
    window.open('http://localhost:4201/articallist');
  }
  getEmail_1() {
    return GeneralService.getEmail_1();
  }
  ngOnInit(): void {}
}
// {
//   destinatari:
//     'info@farmaciadonato.com',
//   body: 'Inserire il contenuto della richiesta di seguito',
//   subject: 'Farmacia Donato: Servizi principali',
//   description:
//     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim',
//   text: 'Articoli',
//   cols: 4,
//   rows: 5,
//   color: 'white',
//   radius: '1',
//   img: 'blog-di-successo.png',
//   redirect: '/articallist',
// },
