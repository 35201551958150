<mat-accordion >
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title style="    justify-content: center;">     
        <h3 class="titleHomePage">IL NOSTRO TEAM</h3>
      </mat-panel-title>
      <mat-panel-description>
        This is a summary of the content
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>This is the primary content of the panel.</p>

    <div class="container">
        <img src="../assets/download_black_24dp (1).jpeg" alt="" style="width:100%;">
        <div class="bottom-left">Bottom Left</div>
        <div class="top-left">Top Left</div>
        <div class="top-right">Top Right</div>
        <div class="bottom-right">Bottom Right</div>
        <div class="centered">Centered</div>
      </div>
    <div style="width: 100%;text-align: center;"
    >
 </div>
  </mat-expansion-panel>
  <!-- <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Self aware panel </mat-panel-title>
      <mat-panel-description>
        Currently I am {{ panelOpenState ? "open" : "closed" }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>I'm visible because I am open</p>
  </mat-expansion-panel> -->
</mat-accordion>
