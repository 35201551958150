<h1 style="padding: 3%; font-size: 40px; color: #8dc1d8">I nostri articoli</h1>
<div style="width: 100%; text-align: center">
  <mat-form-field style="width: 20%; margin: 0px 20% 0px 0px" appearance="fill">
    <mat-label>Selezione un anno</mat-label>
    <mat-select [(value)]="anno">
      <mat-option (click)="filterPeriod()" value="2010">2010</mat-option>
      <mat-option (click)="filterPeriod()" value="2011">2011</mat-option>
      <mat-option (click)="filterPeriod()" value="2012">2012</mat-option>
      <mat-option (click)="filterPeriod()" value="2013">2013</mat-option>
      <mat-option (click)="filterPeriod()" value="2014">2014</mat-option>
      <mat-option (click)="filterPeriod()" value="2015">2015</mat-option>
      <mat-option (click)="filterPeriod()" value="2016">2016</mat-option>
      <mat-option (click)="filterPeriod()" value="2017">2017</mat-option>
      <mat-option (click)="filterPeriod()" value="2018">2018</mat-option>
      <mat-option (click)="filterPeriod()" value="2019">2019</mat-option>
      <mat-option (click)="filterPeriod()" value="2020">2020</mat-option>
      <mat-option (click)="filterPeriod()" value="2021">2021</mat-option>
      <mat-option (click)="filterPeriod()" value="0"> </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 20%; margin: auto" appearance="fill">
    <mat-label>Selezione un mese</mat-label>
    <mat-select [(value)]="mese">
      <mat-option (click)="filterPeriod()" value="1">Gennaio</mat-option>
      <mat-option (click)="filterPeriod()" value="2">Febbraio</mat-option>
      <mat-option (click)="filterPeriod()" value="3">Marzo</mat-option>
      <mat-option (click)="filterPeriod()" value="4">Aprile</mat-option>
      <mat-option (click)="filterPeriod()" value="5">Maggio</mat-option>
      <mat-option (click)="filterPeriod()" value="6">Giugno</mat-option>
      <mat-option (click)="filterPeriod()" value="7">Luglio</mat-option>
      <mat-option (click)="filterPeriod()" value="8">Agosto</mat-option>
      <mat-option (click)="filterPeriod()" value="9">Settembre</mat-option>
      <mat-option (click)="filterPeriod()" value="10">Ottobre</mat-option>
      <mat-option (click)="filterPeriod()" value="11">Novembre</mat-option>
      <mat-option (click)="filterPeriod()" value="12">Dicembre</mat-option>
      <mat-option (click)="filterPeriod()" value="0"> </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-form-field style="width: 60%; margin: auto">
      <mat-label>Che cosa ti interessa leggere?</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Mia"
        #input
      />
    </mat-form-field>
  </div>
  <div style="width: 100%">
    <mat-paginator
      class="marginless-paginator-range-label"
      style="width: fit-content; margin: auto"
      [length]="7130"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator>
    <!-- <mat-paginator
      style-paginator
      showFirstLastButtons
      class="marginless-paginator-range-label"
      style="width: fit-content; margin: auto"
      [showTotalPages]="1"
      [length]="7130"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator> -->
  </div>
</div>

<mat-grid-list
  [cols]="isMobile() ? '1' : isTablet() ? '2' : '3'"
  rowHeight="6:4"
  gutterSize="10px"
  style="margin: 0 4% 0 4%"
>
  <mat-grid-tile
    *ngFor="let tile of obs | async"
    [colspan]="isMobile() ? '1' : isTablet() ? '2' : '3'"
    [rowspan]="tile.rows"
    [style.background]="tile.color"
  >
    <div class="container">
      <img src="../assets/{{ tile.img }}" alt="" />
      <div class="container__text">
        <h1>{{ tile.text }}</h1>

        <p>
          {{ tile.description }}
        </p>
        <div class="container__text__timing">
          <div class="container__text__timing_time">
            <h2>Autore</h2>
            <p>{{ tile.author }}</p>
          </div>
          <!-- <div class="container__text__timing_time">
            <h2>Mese</h2>
            <p>{{ tile.mese }}</p>
          </div>-->
          <div class="container__text__timing_time">
            <h2>Anno</h2>
            <p>{{ tile.anno }}</p>
          </div>
        </div>
        <button (click)="openDialog(tile)" class="btn">
          Leggi l'articolo <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>

    <!-- <div class="a-box" style="width: 100%; height: 500px">
      <div class="img-container" style="width: 100%">
        <div class="img-inner" style="width: 100%">
          <div class="inner-skew{{ tile.radius }}" style="width: 100%">
            <img src="../assets/altriservizi_clicca.png" />
          </div>
        </div>
      </div>
      <div class="text-container" style="height: 300px">
        <h3>{{ tile.text }}</h3>
        <div style="text-align: justify; height: 78px">
          {{ tile.description }}
        </div>
        <div style="text-align: center; width: 100%">
          <button (click)='openDialog(tile)'
            style="margin: auto; width: 60%"
            mat-stroked-button
            color="primary"
          >
            Primary
          </button>

          <a
            style="margin: 0px 0px 0px 30%"
            class="icon-container"
            href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="36px"
              viewBox="0 0 24 24"
              width="36px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div> -->
  </mat-grid-tile>
</mat-grid-list>
