import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  isMobile() {
    return GeneralService.isMobile();
  }
  isTablet() {
    return GeneralService.isTablet();
  }
  isDesktop() {
    return GeneralService.isDesktop();
  }
  title = 'My first AGM project';
  lat = 45.0738929;
  lng = 7.6087233;


}
