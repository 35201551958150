<mat-grid-list
  [cols]="isMobile() ? '4' : isTablet() ? '4' : '9'"
  rowHeight="100px"
  gutterSize="10px"
  style="margin: 4%"
>
  <mat-grid-tile
    [colspan]="isMobile() ? '4' : isTablet() ? '4' : '5'"
    [rowspan]="isMobile() ? '5' : isTablet() ? '5' : '5'"
  >
    <div
      class="cardHomePage"
      [style]="isMobile() ? 'width:100%' : 'width:100%'"
    >
      <!-- <h3 class="titleHomePage">Dove siamo con mappa</h3> -->
      <h3 class="titleHomePage" style="line-height: 2">DOVE SIAMO</h3>
      <div
        [style]="isMobile() ? 'width:90%;margin:auto' : 'width:90%;margin:auto'"
      >
        <div class="agm-wrapper">
          <agm-map
            style="margin: auto; width: 100%"
            [latitude]="lat"
            [zoom]="16"
            [longitude]="lng"
          >
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile
    [colspan]="isMobile() ? '4' : isTablet() ? '4' : '4'"
    [rowspan]="isMobile() ? '9' : isTablet() ? '5' : '5'"
  >
    <div class="cardHomePage">
      <h3 class="titleHomePage" id="team" style="line-height: 2">CHI SIAMO</h3>
      <p
        class="description"
        [style]="
          isMobile()
            ? 'line-height: 2;padding: 4%; text-align: justify;width:90%;min-height: min-content;'
            : isTablet()
            ? 'line-height: 2.5;padding: 4%; text-align: justify;width:90%;min-height: min-content;'
            : 'line-height:2;padding:0px 4%; text-align: justify;width:90%;min-height: min-content;margin: 20px auto;'
        "
      >
        La Farmacia Donato di Torino, dal 1977 con la Dott.ssa Giulia e con il
        Dott. Francesco, i figli ed i collaboratori, offre la propria
        professionalità e competenza ai propri clienti. Ascoltando i vostri
        problemi vi aiuteremo a risolverli consigliando al meglio per soddisfare
        le vostre esigenze e salvaguardare la vostra salute. Il nostro punto di
        forza è il Laboratorio, dove realizziamo preparati galenici di qualità.
        <br />
        Il nostro consiglio si può basare su terapie personalizzate e naturali,
        con rimedi omeopatici e/o fitoterapici. <br />
        Non mancano neppure integratori per i vostri amici a quattro zampe!
        <br />
        Il reparto cosmesi soddisferà ogni vostra esigenza con prodotti
        all’avanguardia, sintetizzati a partire da principi attivi naturali.
        <br />
        Vi invitiamo a provare anche i prodotti della nostra linea
      </p>
      <!-- <img
        *ngIf="isMobile()"
        style="width: 90%; border-radius: 35px; height: 70%"
        src="./assets/FARMACIA DONATO_TORINO_hd-17.jpg"
        alt=""
      /> -->
    </div></mat-grid-tile
  >
</mat-grid-list>
<!-- <div style="width: 80%;margin: auto;margin-top:25px;height: 330px;">
  <app-team style="height: 100%;"></app-team>

</div> -->

<!-- 
<div class="row">
  <div class="columnLarge">
    <div class="cardHomePage">
       <h3 class="titleHomePage">DOVE SIAMO</h3>
      <div style="width: 100%">
        <div class="agm-wrapper">
          <agm-map
            style="margin: auto; width: 100%"
            [latitude]="lat"
            [zoom]="16"
            [longitude]="lng"
          >
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>

  <div class="columnLarge">
    <div class="cardHomePage">
      <h3 class="titleHomePage">CHI SIAMO</h3>
      <p class="description" style="padding: 4%; text-align: justify">
        La Farmacia Donato di Torino, dal 1977 con la Dott.ssa Giulia, ed ora
        con il Dott. Francesco ed i collaboratori, offre la propria
        professionalità e competenza ai clienti. I nostri obiettivi sono la
        disponibilità ad ascoltare e proporre i migliori consigli per soddisfare
        al meglio le vostre esigenze e salvaguardare la vostra salute.
        Partecipiamo ad attività formative al fine di mantenerci aggiornati e
        competenti. Il nostro punto di forza è il Laboratorio, in cui da sempre
        si lavora con precisione ed accuratezza per garantire prodotti sicuri e
        di qualità. Oltre alla vasta gamma di preparazioni galeniche e farmaci
        tradizionali, sono disponibili rimedi omeopatici, fitoterapici, farmaci
        veterinari ed alimenti dietetici. La selezione dei prodotti viene
        effettuata con un’attenta ricerca dei migliori marchi disponibili sul
        mercato. Il reparto cosmesi soddisfa ogni esigenza con prodotti sia di
        aziende conosciute sia all’avanguardia, che lavorano con ingredienti
        totalmente naturali. Vi invitiamo a provare anche la nostra linea
        personalizzata.
      </p>
    </div>
  </div>
</div> -->

<div class="row">
  <app-main-list style="width: 95%; margin: auto"></app-main-list>
</div>
