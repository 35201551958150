import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-modale',
  templateUrl: './modale.component.html',
  styleUrls: ['./modale.component.scss'],
})
export class ModaleComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModaleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  a = '<div>aa</div>';
  ngOnInit(): void {
    // document.getElementsByClassName('cdk-overlay-container')[0].scrollTop = 0;
    setTimeout(function () {
      document.getElementById('privacy').scrollTop -=
        document.getElementById('privacy').scrollTop;
    }, 1000); // 3000 millisecondi = 3 secondi

    throw new Error('Method not implemented.');
  }
  isMobile() {
    return GeneralService.isMobile();
  }
  isTablet() {
    return GeneralService.isTablet();
  }
  isDesktop() {
    return GeneralService.isDesktop();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getEmail_1() {
    return GeneralService.getEmail_1();
  }
  genericSubject_EMAIL() {
    return GeneralService.genericSubject_EMAIL(this.data.title);
  }
  genericBody_EMAIL() {
    return GeneralService.genericBody_EMAIL(this.data.title);
  }
  emailRedirect() {
    window.open(
      'https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=' +
        this.getEmail_1() +
        '&su=' +
        this.genericSubject_EMAIL() +
        '&body=' +
        this.genericBody_EMAIL()
    );
  }
}
