<!-- <div class="navbar">
  <a hr style="width:100%;margin: 10px 0px 10px 0px" ef="#home">Home</a>
  <a hr style="width:100%;margin: 10px 0px 10px 0px" ef="#news">News</a>
  <a hr style="width:100%;margin: 10px 0px 10px 0px" ef="#contact">Contact</a>
</div> -->
<div id="privacy-banner-container">
  <div id="privacy-banner">
    <div [class]="isMobile() ? 'bunner-content-mobile' : 'bunner-content'">
      Abbiamo aggiornato la nostra politica sulla privacy per fornirvi maggiori
      informazioni su come proteggiamo i vostri dati personali. <br />
      Leggetela
      <b
        style="cursor: pointer; text-decoration: underline"
        (click)="openDialog('Policy Privacy')"
      >
        qui</b
      >
      prima di proseguire sul nostro sito.
    </div>

    <br />
    <button id="accept-button" (click)="hide()">Accetta</button>
  </div>
</div>

<div>
  <div>
    <img
      *ngIf="!isMobile()"
      class="hero-image"
      src="./assets/FARMACIA DONATO_TORINO_hd-3.jpg"
      alt=""
      style="object-fit: cover"
    />
    <img
      *ngIf="isMobile()"
      class="hero-image"
      src="./assets/FARMACIA DONATO_TORINO_hd-37.jpg"
      alt=""
    />
    <div
      class="test-button"
      [style]="isMobile() || isTablet() ? 'width:90%' : 'width:35%'"
    >
      <span *ngIf="!isMobile()" (click)="phoneRedirect(getCellulare())">
        <button
          mat-mini-fab
          color="accent"
          aria-label="Example icon button with a plus one icon"
        >
          <mat-icon>phone</mat-icon>
        </button>
        <!-- <button class="mybutton">email@djjd.com</button> -->
      </span>
      <span
        class="mybutton"
        style="cursor: pointer"
        (click)="phoneRedirect(getCellulare())"
      >
        {{ getCellulare() }}
      </span>
      <span
        [style]="
          !isMobile()
            ? 'width: 3px;background-color: transparent;height: 100%;padding: 40px;'
            : 'width: 0px;background-color: transparent;height: 0px;padding: 0px;'
        "
      ></span>

      <hr *ngIf="isMobile()" />
      <span *ngIf="!isMobile()" (click)="emailRedirect()">
        <button
          mat-mini-fab
          color="accent"
          aria-label="Example icon button with a plus one icon"
        >
          <mat-icon>email</mat-icon>
        </button>
      </span>

      <span class="mybutton" style="cursor: pointer" (click)="emailRedirect()">
        {{ getEmail_1() }}
      </span>
    </div>

    <!-- <div
      class="test-button"
      style="top: 100px"
      [style]="isMobile() || isTablet() ? 'width:90%' : 'width:35%'"
    >
      <span
        style="
          top: 100px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 25px;
        "
      >
        Aperti Domenica 20 marzo.
      </span>
      <span
        style="
          top: 100px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 25px;
          text-decoration: underline;
          cursor: pointer;
        "
      >
        Scopri di più sui tamponi
      </span>
    </div> -->
    <div class="hero-text">
      <!-- *ngIf="!isMobile()" -->
      <span
        *ngIf="isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 30px;
        "
      >
        <br />
        <br />
      </span>
      <!-- <span
        *ngIf="!isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 30px;
        "
      >
        Aperti Domenica 20 marzo.
        <br />
      </span> -->
      <!-- <span
      *ngIf="isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 24px;
        "
      >
        Aperti Domenica 20 marzo.
        <br />
      </span> -->

      <span
        *ngIf="!isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 30px;
          text-decoration: underline;
          cursor: pointer;
        "
        (click)="openDialog()"
      >
        Autoanalisi Creatinina
      </span>
      <span
        *ngIf="isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 24px;
          text-decoration: underline;
          cursor: pointer;
        "
        (click)="openDialog()"
      >
        Autoanalisi Creatinina
      </span>
      <br />
      <!-- <span
        *ngIf="!isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 30px;
          text-decoration: underline;
          cursor: pointer;
        "
        (click)="openDialog('Tamponi')"
      >
        Tamponi
      </span>
      <span
        *ngIf="isMobile()"
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 24px;
          text-decoration: underline;
          cursor: pointer;
        "
        (click)="openDialog('Tamponi')"
      >
        Tamponi
      </span> -->
      <!-- questo è lo span da scommentare -->
      <!-- <span
        style="
          top: 0px;
          width: 90%;
          color: white;
          font-weight: bold;
          font-size: 30px;
          cursor: pointer;
          animation: blinker 0.8s linear infinite;
        "
      >
        Aperti Domenica 24 Dicembre <br />
  
      </span> -->
      <h1
        [style]="
          !isMobile()
            ? 'font-size: 83px; text-shadow: 2px 2px black;'
            : 'font-size: 42px; text-shadow: 2px 2px black;'
        "
      >
        Farmacia Donato
      </h1>

      <p
        *ngIf="!isMobile()"
        style="font-size: 30px; font-weight: bold; text-shadow: 1px 1px black"
      >
        Dal 1977
      </p>

      <mat-grid-list
        [cols]="isMobile() ? '1' : isTablet() ? '1' : '2'"
        rowHeight="100px"
      >
        <mat-grid-tile>
          <div
            style="cursor: pointer"
            (click)="scroll('servizi')"
            class="btn-2"
          >
            <a
              ><span style="width: 100%; height: 100%"
                >I nostri servizi</span
              ></a
            >
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="!isMobile()">
          <div style="cursor: pointer" (click)="scroll('team')" class="btn-2">
            <a><span style="width: 100%; height: 100%">Il nostro Team</span></a>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>

  <p class="containerCardList">
    <router-outlet class="card-list"></router-outlet>
    <!-- <app-home-page ></app-home-page> -->
  </p>

  <div class="footer-version2" style="padding-top: 14px">
    <div style="color: transparent; height: 100%"></div>
    <mat-grid-list
      [cols]="isMobile() ? '3' : isTablet() ? '3' : '3'"
      rowHeight="100px"
      gutterSize="10px"
    >
      <mat-grid-tile
        [colspan]="isMobile() ? '3' : isTablet() ? '3' : '1'"
        [rowspan]="isMobile() ? '3' : isTablet() ? '4' : '4'"
      >
        <ul
          [style]="
            !isMobile()
              ? 'height: 90%; width: 80%; margin: auto;padding:0;margin-top:0px'
              : 'height: 90%;width:80%;padding:0;'
          "
        >
          <li>
            <h2
              style="
                text-transform: uppercase;
                height: 30px;
                margin-bottom: 35px;
              "
            >
              Offerte, Sconti e News
            </h2>
          </li>

          <li style="width: 100%">
            <p
              class="parag"
              style="cursor: pointer"
              (click)="scroll('Tirocinio')"
            >
              Tirocinio
            </p>
            <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          </li>
          <li style="width: 100%">
            <p
              class="parag"
              style="cursor: pointer"
              (click)="scroll('Autoanalisi')"
            >
              Autoanalisi
            </p>
            <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          </li>
          <li style="width: 100%">
            <p
              class="parag"
              style="cursor: pointer"
              (click)="scroll('Laboratorio')"
            >
              Laboratorio
            </p>
            <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          </li>
          <li style="width: 100%">
            <p
              class="parag"
              style="cursor: pointer"
              (click)="scroll('Altri servizi')"
            >
              Altri servizi
            </p>
            <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          </li>
          <li style="width: 100%">
            <p class="parag">
              La Tua Farmacia ti premia, con la tessera punti! <br />
              La card viene rilasciata gratuitamente e senza alcun vincolo.
            </p>
          </li>
        </ul>
        <div></div>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="isMobile() ? '3' : isTablet() ? '3' : '1'"
        [rowspan]="isMobile() ? '6' : isTablet() ? '4' : '4'"
      >
        <ul
          [style]="
            isTablet()
              ? 'height: 100%; width: 83%; margin: auto'
              : !isMobile()
              ? 'height: 100%;width:80%;padding:0;'
              : 'height: 100%;width:80%;padding:0;'
          "
        >
          <li>
            <h2
              [style]="
                !isMobile()
                  ? 'text-transform: uppercase; height: 30px'
                  : 'text-transform: uppercase; height: 30px;'
              "
            >
              ORARI
              <!-- <p
                *ngIf="isMobile()"
                style="margin-top: 0px"
                class="paragBlink blink_me"
              >
                Aperti Domenica 20 marzo<span class="paragBlinkNoPhoneSmall">
                  (08:30 - 19:30)
                </span>
              </p> -->
              <!-- <span *ngIf="!isMobile()" class="paragBlinkNoPhone blink_me">
                - Aperti Domenica 20 marzo 2022
                <span class="paragBlinkNoPhoneSmall">
                  (08:30 - 19:30)
                </span></span
              > -->
            </h2>
          </li>
          <li [style]="!isMobile() ? 'width: 100%' : 'width: 100%;'">
            <p class="parag">Lunedì - Sabato: 08:30 - 19:30</p>
            <!-- <p class="paragBlink blink_me">
              Aperti Domenica 20 marzo 2022
            </p> -->
            <!-- <p class="paragBlink blink_me" (click)="openDialog()">
              Si eseguono tamponi rapidi anche senza prenotazione dalle 8.30
              alle 12.30 e dalle 15.00 alle 18.30
            </p> -->
          </li>
          <li [style]="!isMobile() ? 'width: 100%' : 'width: 100%;'">
            <!-- <p class="parag">Sabato 08:30-19:30</p> -->
            <hr style="width: 100%; margin: 37px 0px 10px 0px" />
          </li>
          <li style="width: 100%">
            <mat-grid-list
              [cols]="isMobile() ? '12' : isTablet() ? '12' : '12'"
              rowHeight="20px"
              gutterSize="10px"
              style="margin: 4%"
            >
              <mat-grid-tile
                style="text-align: center"
                [colspan]="isMobile() ? 12 : isTablet() ? 6 : 6"
                [rowspan]="isMobile() ? 6 : isTablet() ? 4 : 4"
              >
                <div (click)="emailRedirect()">
                  <img
                    style="cursor: pointer"
                    src="./assets/icons8-email-aperta-64.png"
                    alt=""
                  />
                  <div
                    style="cursor: pointer"
                    (click)="emailRedirect()"
                    class="icon-desc-footer"
                  >
                    {{ getEmail_1().replace("info", "") }}
                  </div>
                </div>
              </mat-grid-tile>
              <mat-grid-tile
                style="text-align: center"
                [colspan]="isMobile() ? 12 : isTablet() ? 6 : 6"
                [rowspan]="isMobile() ? 6 : isTablet() ? 4 : 4"
              >
                <div
                  style="cursor: pointer"
                  (click)="phoneRedirect(getCellulare())"
                >
                  <img
                    src="./assets/icons8-messaggio-del-telefono-64.png"
                    alt=""
                  />
                  <div
                    style="cursor: pointer"
                    (click)="phoneRedirect(getCellulare())"
                    class="icon-desc-footer"
                  >
                    {{ getCellulare() }}
                  </div>
                </div>
              </mat-grid-tile>
              <mat-grid-tile
                (click)="instagramRediret()"
                style="text-align: center"
                [colspan]="isMobile() ? 12 : isTablet() ? 6 : 6"
                [rowspan]="isMobile() ? 6 : isTablet() ? 4 : 4"
              >
                <div class="col">
                  <svg
                    style="margin: 0px 40px 0 40px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 128"
                    width="64px"
                    height="64px"
                  >
                    <path
                      fill="#ff697b"
                      d="M86.2,109H41.8C32,109,24,101,24,91.2V46.8C24,37,32,29,41.8,29h44.4C96,29,104,37,104,46.8v44.4C104,101,96,109,86.2,109z"
                    />
                    <path
                      fill="#fff"
                      d="M24,46.8V58h80V46.8C104,37,96,29,86.2,29H41.8C32,29,24,37,24,46.8z"
                    />
                    <path
                      fill="#444b54"
                      d="M86.2,112H41.8C30.3,112,21,102.7,21,91.2V46.8C21,35.3,30.3,26,41.8,26h44.4c11.5,0,20.8,9.3,20.8,20.8v44.4C107,102.7,97.7,112,86.2,112z M41.8,32C33.6,32,27,38.6,27,46.8v44.4c0,8.1,6.6,14.8,14.8,14.8h44.4c8.1,0,14.8-6.6,14.8-14.8V46.8c0-8.1-6.6-14.8-14.8-14.8H41.8z"
                    />
                    <path
                      fill="#fff"
                      d="M64 54A15 15 0 1 0 64 84A15 15 0 1 0 64 54Z"
                    />
                    <path
                      fill="#444b54"
                      d="M64,87c-9.9,0-18-8.1-18-18c0-1.7,1.3-3,3-3s3,1.3,3,3c0,6.6,5.4,12,12,12s12-5.4,12-12s-5.4-12-12-12c-1.7,0-3-1.3-3-3s1.3-3,3-3c9.9,0,18,8.1,18,18S73.9,87,64,87z"
                    />
                    <path
                      fill="#444b54"
                      d="M53.8 60.9c-.2 0-.4 0-.6-.1s-.4-.1-.6-.2c-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2S54 60.9 53.8 60.9zM86 43A4 4 0 1 0 86 51 4 4 0 1 0 86 43zM19 112.3c-1.6 0-3-1.3-3-3 0-1.7 1.3-3 3-3l90-.5c0 0 0 0 0 0 1.6 0 3 1.3 3 3 0 1.7-1.3 3-3 3L19 112.3C19 112.3 19 112.3 19 112.3zM124 111.8c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.4-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C125.6 111.5 124.8 111.8 124 111.8z"
                    />
                  </svg>
                  <div
                    class="icon-desc-footer"
                    (click)="instagramRediret()"
                    style="cursor: pointer"
                  >
                    Farmacia Donato
                  </div>
                </div>
              </mat-grid-tile>
              <mat-grid-tile
                (click)="googleMapsRedirect()"
                style="text-align: center"
                [colspan]="isMobile() ? 12 : isTablet() ? 6 : 6"
                [rowspan]="isMobile() ? 6 : isTablet() ? 4 : 4"
              >
                <div class="col">
                  <svg
                    width="64px"
                    height="64px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-label="Google Maps"
                    role="img"
                    viewBox="0 0 512 512"
                  >
                    <rect
                      id="a"
                      width="512"
                      height="512"
                      x="0"
                      y="0"
                      rx="15%"
                      fill="#fff"
                    />
                    <clipPath id="b"><use xlink:href="#a" /></clipPath>
                    <g clip-path="url(#b)">
                      <path fill="#35a85b" d="M0 512V0h512z" />
                      <path fill="#5881ca" d="M256 288L32 512h448z" />
                      <path fill="#c1c0be" d="M288 256L512 32v448z" />
                      <path
                        stroke="#fadb2a"
                        stroke-width="71"
                        d="M0 512L512 0"
                      />
                      <path
                        fill="none"
                        stroke="#f2f2f2"
                        stroke-width="22"
                        d="M175 173h50a50 54 0 1 1-15-41"
                      />
                      <path
                        fill="#de3738"
                        d="M353 85a70 70 0 0 1 140 0c0 70-70 70-70 157 0-87-70-87-70-157"
                      />
                      <circle cx="423" cy="89" r="25" fill="#7d2426" />
                    </g>
                  </svg>
                  <div
                    class="icon-desc-footer"
                    (click)="googleMapsRedirect()"
                    style="cursor: pointer"
                  >
                    Dove siamo
                  </div>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </li>
        </ul>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="isMobile() ? '3' : isTablet() ? '3' : '1'"
        [rowspan]="isMobile() ? '5' : isTablet() ? '4' : '4'"
      >
        <ul
          [style]="
            !isMobile()
              ? 'height: 100%; width: 80%; margin: auto'
              : 'height: 100%;width:100%;padding:0;'
          "
        >
          <li>
            <h2
              [style]="
                !isMobile()
                  ? 'text-transform: uppercase; height: 30px'
                  : 'text-transform: uppercase; height: 30px;padding: 0 0 0px 30px;'
              "
            >
              COME RAGGIUNGERCI
            </h2>
          </li>
          <li
            [style]="
              !isMobile()
                ? 'width: 100%;text-align: center'
                : 'padding: 0px 0px 0px 0px;text-align: center;width: 90%;margin: auto;'
            "
          >
            <h3 style="text-transform: uppercase; margin: 0px">METRO</h3>
            <img
              style="margin-top: 15px"
              src="./assets/icons8-metropolitana-64.png"
              alt=""
            />

            <p>
              Scendere alla fermata Marche (lato via Eritrea) <br />
              continuare a piedi verso Collegno per 500m
            </p>
            <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          </li>
          <li
            [style]="
              !isMobile()
                ? 'width: 100%;text-align: center'
                : 'padding: 0px 0px 0px 0px;text-align: center;width: 90%;margin: auto;'
            "
          >
            <h3 style="text-transform: uppercase; margin: 0px">PULLMAN</h3>

            <img src="./assets/icons8-autobus-64.png" alt="" />
            <p>
              Linea 33 o 36, scendere alla fermata Rieti Nord <br />
              AUTO: Corso Francia, 385 - Torino
            </p>
          </li>
        </ul>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="isMobile() ? '3' : isTablet() ? '3' : '3'"
        [rowspan]="isMobile() ? '3' : isTablet() ? '3' : '3'"
      >
        <div style="width: 80%; margin: auto; text-align: center">
          <img
            src="./assets/3.png"
            [style]="
              isDesktop()
                ? 'opacity: 0.8; width: 50%;max-height:135px;'
                : 'opacity: 0.8; width: 100%;max-height:135px;margin-bottom: 32%;'
            "
            alt=""
          />
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<!-- <div
              class="flex-grid"
              style="text-align: center; margin: 0; padding: 0px 15px 0px 0px"
            >
              <div class="col" (click)="emailRedirect()">
                <img src="./assets/icons8-email-aperta-64.png" alt="" />
                <div class="icon-desc-footer">{{ getEmail_1() }}</div>
              </div>
              <div class="col" (click)="phoneRedirect(getCellulare())">
                <img
                  src="./assets/icons8-messaggio-del-telefono-64.png"
                  alt=""
                />
                <div class="icon-desc-footer">{{ getCellulare() }}</div>
              </div>
              <div class="col" >
                <svg
                  style="margin: 0px 40px 0 40px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  width="64px"
                  height="64px"
                >
                  <path
                    fill="#ff697b"
                    d="M86.2,109H41.8C32,109,24,101,24,91.2V46.8C24,37,32,29,41.8,29h44.4C96,29,104,37,104,46.8v44.4C104,101,96,109,86.2,109z"
                  />
                  <path
                    fill="#fff"
                    d="M24,46.8V58h80V46.8C104,37,96,29,86.2,29H41.8C32,29,24,37,24,46.8z"
                  />
                  <path
                    fill="#444b54"
                    d="M86.2,112H41.8C30.3,112,21,102.7,21,91.2V46.8C21,35.3,30.3,26,41.8,26h44.4c11.5,0,20.8,9.3,20.8,20.8v44.4C107,102.7,97.7,112,86.2,112z M41.8,32C33.6,32,27,38.6,27,46.8v44.4c0,8.1,6.6,14.8,14.8,14.8h44.4c8.1,0,14.8-6.6,14.8-14.8V46.8c0-8.1-6.6-14.8-14.8-14.8H41.8z"
                  />
                  <path
                    fill="#fff"
                    d="M64 54A15 15 0 1 0 64 84A15 15 0 1 0 64 54Z"
                  />
                  <path
                    fill="#444b54"
                    d="M64,87c-9.9,0-18-8.1-18-18c0-1.7,1.3-3,3-3s3,1.3,3,3c0,6.6,5.4,12,12,12s12-5.4,12-12s-5.4-12-12-12c-1.7,0-3-1.3-3-3s1.3-3,3-3c9.9,0,18,8.1,18,18S73.9,87,64,87z"
                  />
                  <path
                    fill="#444b54"
                    d="M53.8 60.9c-.2 0-.4 0-.6-.1s-.4-.1-.6-.2c-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2S54 60.9 53.8 60.9zM86 43A4 4 0 1 0 86 51 4 4 0 1 0 86 43zM19 112.3c-1.6 0-3-1.3-3-3 0-1.7 1.3-3 3-3l90-.5c0 0 0 0 0 0 1.6 0 3 1.3 3 3 0 1.7-1.3 3-3 3L19 112.3C19 112.3 19 112.3 19 112.3zM124 111.8c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.4-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C125.6 111.5 124.8 111.8 124 111.8z"
                  />
                </svg>
                <div class="icon-desc-footer">Farmacia Donato</div>
              </div>
            </div>
           </li> -->
<!-- <li style="width: 100%; text-align: center">
            <svg
              style="margin: 0px 15px 0 15px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 128"
              width="64px"
              height="64px"
            >
              <path
                fill="#659ec9"
                d="M84.9,48.1H70.7v-9.3c0-3.5,2.3-4.3,4-4.3c1.6,0,10.1,0,10.1,0V19.1L70.8,19C55.5,19,52,30.5,52,37.9v10.3h-8.9V64H52c0,20.4,0,45,0,45h18.7c0,0,0-24.8,0-45h12.6L84.9,48.1z"
              />
              <path
                fill="#fff"
                d="M70.8,29l13.8,0.1v-10L70.8,19C55.5,19,52,30.5,52,37.9v10C52,40.5,55.5,29,70.8,29z"
              />
              <path
                fill="#444b54"
                d="M70.7,112H52c-1.7,0-3-1.3-3-3V67h-5.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H52c1.7,0,3,1.3,3,3v42h12.7V64c0-1.7,1.3-3,3-3h9.9l1-9.9H70.7c-1.7,0-3-1.3-3-3v-9.3c0-5.1,3.5-7.3,7-7.3h7.1V22l-10.9,0C66.1,22,55,23.5,55,37.9v10.3c0,1.7-1.3,3-3,3h-8.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H49v-7.3C49,27.8,54.7,16,70.8,16l13.9,0.1c1.7,0,3,1.3,3,3v15.4c0,1.7-1.3,3-3,3H74.6c-0.6,0-1,0-1,1.3v6.3h11.2c0.8,0,1.7,0.4,2.2,1c0.6,0.6,0.8,1.5,0.8,2.3l-1.6,15.9c-0.2,1.5-1.4,2.7-3,2.7h-9.6v42C73.7,110.7,72.3,112,70.7,112z"
              />
              <path
                fill="#444b54"
                d="M84.7 112H42c-1.7 0-3-1.3-3-3s1.3-3 3-3h42.7c1.7 0 3 1.3 3 3S86.3 112 84.7 112zM100.7 112c-.8 0-1.6-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.3.3-.5.1-.2.2-.3.4-.5 1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1C102.2 111.7 101.5 112 100.7 112z"
              />
            </svg>

            <svg
              style="margin: 0px 40px 0 40px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 128"
              width="64px"
              height="64px"
            >
              <path
                fill="#ff697b"
                d="M86.2,109H41.8C32,109,24,101,24,91.2V46.8C24,37,32,29,41.8,29h44.4C96,29,104,37,104,46.8v44.4C104,101,96,109,86.2,109z"
              />
              <path
                fill="#fff"
                d="M24,46.8V58h80V46.8C104,37,96,29,86.2,29H41.8C32,29,24,37,24,46.8z"
              />
              <path
                fill="#444b54"
                d="M86.2,112H41.8C30.3,112,21,102.7,21,91.2V46.8C21,35.3,30.3,26,41.8,26h44.4c11.5,0,20.8,9.3,20.8,20.8v44.4C107,102.7,97.7,112,86.2,112z M41.8,32C33.6,32,27,38.6,27,46.8v44.4c0,8.1,6.6,14.8,14.8,14.8h44.4c8.1,0,14.8-6.6,14.8-14.8V46.8c0-8.1-6.6-14.8-14.8-14.8H41.8z"
              />
              <path
                fill="#fff"
                d="M64 54A15 15 0 1 0 64 84A15 15 0 1 0 64 54Z"
              />
              <path
                fill="#444b54"
                d="M64,87c-9.9,0-18-8.1-18-18c0-1.7,1.3-3,3-3s3,1.3,3,3c0,6.6,5.4,12,12,12s12-5.4,12-12s-5.4-12-12-12c-1.7,0-3-1.3-3-3s1.3-3,3-3c9.9,0,18,8.1,18,18S73.9,87,64,87z"
              />
              <path
                fill="#444b54"
                d="M53.8 60.9c-.2 0-.4 0-.6-.1s-.4-.1-.6-.2c-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2S54 60.9 53.8 60.9zM86 43A4 4 0 1 0 86 51 4 4 0 1 0 86 43zM19 112.3c-1.6 0-3-1.3-3-3 0-1.7 1.3-3 3-3l90-.5c0 0 0 0 0 0 1.6 0 3 1.3 3 3 0 1.7-1.3 3-3 3L19 112.3C19 112.3 19 112.3 19 112.3zM124 111.8c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.4-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C125.6 111.5 124.8 111.8 124 111.8z"
              />
            </svg>
            <svg
              style="margin: 0px 15px 0 15px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 128"
              width="64px"
              height="64px"
            >
              <path
                fill="#76ecff"
                d="M100.2,42.5c-0.4,0.4-0.7,1-0.6,1.6c0.1,1.9,2.6,47.4-45.7,58.8c-0.2,0-19.4,4-36-4.2c7-0.2,17.1-2,24.1-9.9c0.5-0.6,0.7-1.4,0.3-2.2c-0.3-0.7-1-1.2-1.8-1.2c0,0,0,0,0,0c-0.1,0.1-10.6,0-15.5-10c1.9,0.1,4.3,0,6.3-0.9c0.8-0.4,1.3-1.2,1.2-2c-0.1-0.9-0.7-1.6-1.6-1.7c-0.5-0.1-12.3-2.7-13.9-15.5c1.8,0.8,4.1,1.4,6.3,1.1c0.8-0.1,1.4-0.7,1.6-1.4c0.2-0.8,0-1.6-0.6-2.1c-0.5-0.4-11.5-10.2-6.3-22.9c5.7,6,22.5,21.4,42.9,20.3c0.6,0,1.1-0.3,1.5-0.8c0.4-0.5,0.5-1.1,0.3-1.7c-0.4-1.5-0.6-3.1-0.6-4.7c0-10.4,8.3-18.9,18.6-18.9c5,0,9.7,2,13.2,5.6c0.4,0.4,0.9,0.6,1.4,0.6c2.1,0,5.6-0.2,9.6-1.8c-1.1,1.4-2.8,3.1-5.3,5.1c-0.7,0.5-1,1.5-0.6,2.3c0.3,0.8,1.2,1.3,2,1.3c0.5,0,2.9-0.2,5.6-0.8C105,37.9,103,39.9,100.2,42.5z"
              />
              <path
                fill="#fff"
                d="M32.1 65.1c.8-.1 1.4-.7 1.6-1.4.2-.8 0-1.6-.6-2.1-.5-.4-11.5-10.2-6.3-22.9 5.7 6 22.5 21.4 42.9 20.3.6 0 1.1-.3 1.5-.8.4-.5.5-1.1.3-1.7-.4-1.5-.6-3.1-.6-4.7 0-10.4 8.3-18.9 18.6-18.9 3.3 0 14.2-3 15.3-4.4-4 1.6-7.5 1.8-9.6 1.8-.5 0-1-.2-1.4-.6-3.5-3.6-8.2-5.6-13.2-5.6C70.3 24.1 62 32.5 62 42.9c0 1.6.2 3.2.6 4.7.1.6 0 1.2-.3 1.7-.4.5-.9.8-1.5.8-20.4 1.2-37.2-14.3-42.9-20.3-5.2 12.6 5.8 22.4 6.3 22.9.6.5.8 1.3.6 2.1-.2.8-.9 1.3-1.6 1.4-2.3.3-4.5-.3-6.3-1.1 1.1 8.5 6.6 12.5 10.4 14.3-.7-1.6-1.2-3.3-1.5-5.4C27.5 64.8 29.8 65.5 32.1 65.1zM40.1 83.4c.8-.4 1.3-1.2 1.2-2-.1-.9-.7-1.6-1.6-1.7-.3-.1-5-1.1-8.8-4.9-1.8.7-4 .8-5.9.7 2.5 5.1 6.5 7.7 9.8 8.9C36.5 84.3 38.5 84.1 40.1 83.4zM50.8 97.7c.5-.6.7-1.4.3-2.2-.3-.7-1-1.2-1.8-1.2 0 0 0 0 0 0-.1 0-5 0-9.7-3.2-6.8 5.9-15.5 7.4-21.8 7.6 8.5 4.2 17.8 5.2 24.7 5.2C45.5 102.4 48.4 100.4 50.8 97.7z"
              />
              <path
                fill="#444b54"
                d="M24.2 55.8c-.7 0-1.4-.2-2-.7-.5-.5-13.1-11.7-7.1-26.3.4-.9 1.2-1.6 2.2-1.8 1-.2 2 .1 2.7.9 16.7 17.5 32.3 19.5 39.3 19.4-.3-1.4-.4-2.8-.4-4.2 0-12 9.7-21.9 21.6-21.9 5.7 0 11 2.2 15 6.2 6.7-.4 10.7-3.7 10.8-3.7 1.3-1.1 3.2-.9 4.2.3 1.1 1.3.9 3.2-.3 4.2-.2.2-5.8 4.9-15 5.2-.1 0-.1 0-.2 0-1.3 0-2.6-.6-3.5-1.5-2.9-3-6.9-4.7-11.1-4.7C72 27.1 65 34.2 65 42.9c0 1.3.2 2.7.5 4 .4 1.5.1 3-.8 4.2-.9 1.2-2.3 2-3.8 2-18 1-33.2-9.9-41.4-17.4-.6 8.4 6.3 14.5 6.6 14.8 1.2 1.1 1.4 3 .3 4.2C25.9 55.4 25 55.8 24.2 55.8zM95.9 27.6C95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6zM99.6 47.1c-1 0-2-.5-2.6-1.5-.8-1.4-.3-3.3 1.1-4.1 5.4-3.1 11-10.2 11.1-10.3 1-1.3 2.9-1.5 4.2-.5 1.3 1 1.5 2.9.5 4.2-.3.3-6.4 8.1-12.8 11.8C100.6 46.9 100.1 47.1 99.6 47.1zM30.8 73.7c-.2 0-.4 0-.6-.1-.6-.1-14.4-3.2-16.3-18.1-.2-1.6 1-3.1 2.6-3.4 1.6-.2 3.1 1 3.4 2.6 1.4 10.7 11.2 12.9 11.6 13 1.6.3 2.7 1.9 2.3 3.6C33.4 72.8 32.2 73.7 30.8 73.7z"
              />
              <path
                fill="#444b54"
                d="M40.5 88.5L40.5 88.5c-1.7.1-3.8-.4-5.1-.8-4.1-1.2-9.8-4-13.1-11-.7-1.5-.1-3.3 1.4-4 1.5-.7 3.3-.1 4 1.4 3.8 7.9 11.9 8.3 12.6 8.4 1.7-.1 3.2 1.3 3.2 3C43.5 87.1 42.1 88.5 40.5 88.5zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM40.6 95c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.5.4s.3.3.4.5.2.3.3.5c.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5s-.2.3-.4.5c-.1.1-.3.3-.5.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C41 94.9 40.8 95 40.6 95z"
              />
              <g>
                <path
                  fill="#444b54"
                  d="M17.9,101.7c-1.6,0-3-1.3-3-2.9c0-1.7,1.3-3,2.9-3.1c5.3-0.1,10.1-1.2,14.1-3.1c1.5-0.7,3.3-0.1,4,1.4c0.7,1.5,0.1,3.3-1.4,4C29.7,100.3,24.1,101.5,17.9,101.7C17.9,101.7,17.9,101.7,17.9,101.7z"
                />
              </g>
              <g>
                <path
                  fill="#444b54"
                  d="M42.3,106.9c-3,0-6.4-0.2-10-0.7c-1.6-0.2-2.8-1.8-2.5-3.4c0.2-1.6,1.8-2.8,3.4-2.5c10.8,1.6,20-0.3,20.1-0.3C99,89.1,96.7,46.1,96.6,44.3c-0.1-1.7,1.1-3.1,2.8-3.2c1.7-0.1,3.1,1.1,3.2,2.8c0.1,2,2.7,49.9-48,61.9C54.2,105.9,49.3,106.9,42.3,106.9z"
                />
              </g>
            </svg>
          </li> -->
<!-- <div
    style="
      background-color: #6b96ab;
      width: 100%;
      padding-bottom: 60px;
      padding-top: 80px;
    "
  >
    <div
      class="flex-grid-thirds"
      style="width: 80%; font-size: 15px; margin: auto"
    >
      <div style="padding: 19px">
        <div>
          <h2 style="text-transform: uppercase; margin: 0px 0px 14px 0px">
            Offerte, Sconti e News
          </h2>

          <p class="parag">Articoli</p>
          <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          <p class="parag">Tirocinio</p>
          <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          <p class="parag">Autoanalisi</p>
          <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          <p class="parag">Laboratorio</p>
          <hr style="width: 100%; margin: 18px 0px 10px 0px" />
          <p class="parag">Altri servizi</p>
        </div>
         <hr />
       </div>
      <div class="col" style="padding: 19px; text-align: center">
        <h2 style="text-transform: uppercase; margin: 0px">ORARI</h2>

        <p class="parag">Lunedì - Venerdì 08:45-19:30</p>
        <p class="parag">Sabato 08:45-13:00</p>

         <div class="flex-grid" style="text-align: center">
          <div class="col" (click)="emailRedirect(getEmail_1())">
            <img src="./assets/icons8-email-aperta-64.png" alt="" />
            <div class="icon-desc-footer">{{getEmail_1()}}</div>
          </div>
          <div class="col" (click)="phoneRedirect('sss')">
            <img src="./assets/icons8-messaggio-del-telefono-64.png" alt="" />
            <div class="icon-desc-footer">+39 011 4032610</div>
          </div>
        </div>

        <hr />

        <svg
          style="margin: 0px 15px 0 15px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
          width="64px"
          height="64px"
        >
          <path
            fill="#659ec9"
            d="M84.9,48.1H70.7v-9.3c0-3.5,2.3-4.3,4-4.3c1.6,0,10.1,0,10.1,0V19.1L70.8,19C55.5,19,52,30.5,52,37.9v10.3h-8.9V64H52c0,20.4,0,45,0,45h18.7c0,0,0-24.8,0-45h12.6L84.9,48.1z"
          />
          <path
            fill="#fff"
            d="M70.8,29l13.8,0.1v-10L70.8,19C55.5,19,52,30.5,52,37.9v10C52,40.5,55.5,29,70.8,29z"
          />
          <path
            fill="#444b54"
            d="M70.7,112H52c-1.7,0-3-1.3-3-3V67h-5.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H52c1.7,0,3,1.3,3,3v42h12.7V64c0-1.7,1.3-3,3-3h9.9l1-9.9H70.7c-1.7,0-3-1.3-3-3v-9.3c0-5.1,3.5-7.3,7-7.3h7.1V22l-10.9,0C66.1,22,55,23.5,55,37.9v10.3c0,1.7-1.3,3-3,3h-8.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H49v-7.3C49,27.8,54.7,16,70.8,16l13.9,0.1c1.7,0,3,1.3,3,3v15.4c0,1.7-1.3,3-3,3H74.6c-0.6,0-1,0-1,1.3v6.3h11.2c0.8,0,1.7,0.4,2.2,1c0.6,0.6,0.8,1.5,0.8,2.3l-1.6,15.9c-0.2,1.5-1.4,2.7-3,2.7h-9.6v42C73.7,110.7,72.3,112,70.7,112z"
          />
          <path
            fill="#444b54"
            d="M84.7 112H42c-1.7 0-3-1.3-3-3s1.3-3 3-3h42.7c1.7 0 3 1.3 3 3S86.3 112 84.7 112zM100.7 112c-.8 0-1.6-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.3.3-.5.1-.2.2-.3.4-.5 1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1C102.2 111.7 101.5 112 100.7 112z"
          />
        </svg>

        <svg
          style="margin: 0px 40px 0 40px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
          width="64px"
          height="64px"
        >
          <path
            fill="#ff697b"
            d="M86.2,109H41.8C32,109,24,101,24,91.2V46.8C24,37,32,29,41.8,29h44.4C96,29,104,37,104,46.8v44.4C104,101,96,109,86.2,109z"
          />
          <path
            fill="#fff"
            d="M24,46.8V58h80V46.8C104,37,96,29,86.2,29H41.8C32,29,24,37,24,46.8z"
          />
          <path
            fill="#444b54"
            d="M86.2,112H41.8C30.3,112,21,102.7,21,91.2V46.8C21,35.3,30.3,26,41.8,26h44.4c11.5,0,20.8,9.3,20.8,20.8v44.4C107,102.7,97.7,112,86.2,112z M41.8,32C33.6,32,27,38.6,27,46.8v44.4c0,8.1,6.6,14.8,14.8,14.8h44.4c8.1,0,14.8-6.6,14.8-14.8V46.8c0-8.1-6.6-14.8-14.8-14.8H41.8z"
          />
          <path fill="#fff" d="M64 54A15 15 0 1 0 64 84A15 15 0 1 0 64 54Z" />
          <path
            fill="#444b54"
            d="M64,87c-9.9,0-18-8.1-18-18c0-1.7,1.3-3,3-3s3,1.3,3,3c0,6.6,5.4,12,12,12s12-5.4,12-12s-5.4-12-12-12c-1.7,0-3-1.3-3-3s1.3-3,3-3c9.9,0,18,8.1,18,18S73.9,87,64,87z"
          />
          <path
            fill="#444b54"
            d="M53.8 60.9c-.2 0-.4 0-.6-.1s-.4-.1-.6-.2c-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2S54 60.9 53.8 60.9zM86 43A4 4 0 1 0 86 51 4 4 0 1 0 86 43zM19 112.3c-1.6 0-3-1.3-3-3 0-1.7 1.3-3 3-3l90-.5c0 0 0 0 0 0 1.6 0 3 1.3 3 3 0 1.7-1.3 3-3 3L19 112.3C19 112.3 19 112.3 19 112.3zM124 111.8c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.4-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C125.6 111.5 124.8 111.8 124 111.8z"
          />
        </svg>
        <svg
          style="margin: 0px 25px 0 25px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
          width="64px"
          height="64px"
        >
          <path
            fill="#76ecff"
            d="M100.2,42.5c-0.4,0.4-0.7,1-0.6,1.6c0.1,1.9,2.6,47.4-45.7,58.8c-0.2,0-19.4,4-36-4.2c7-0.2,17.1-2,24.1-9.9c0.5-0.6,0.7-1.4,0.3-2.2c-0.3-0.7-1-1.2-1.8-1.2c0,0,0,0,0,0c-0.1,0.1-10.6,0-15.5-10c1.9,0.1,4.3,0,6.3-0.9c0.8-0.4,1.3-1.2,1.2-2c-0.1-0.9-0.7-1.6-1.6-1.7c-0.5-0.1-12.3-2.7-13.9-15.5c1.8,0.8,4.1,1.4,6.3,1.1c0.8-0.1,1.4-0.7,1.6-1.4c0.2-0.8,0-1.6-0.6-2.1c-0.5-0.4-11.5-10.2-6.3-22.9c5.7,6,22.5,21.4,42.9,20.3c0.6,0,1.1-0.3,1.5-0.8c0.4-0.5,0.5-1.1,0.3-1.7c-0.4-1.5-0.6-3.1-0.6-4.7c0-10.4,8.3-18.9,18.6-18.9c5,0,9.7,2,13.2,5.6c0.4,0.4,0.9,0.6,1.4,0.6c2.1,0,5.6-0.2,9.6-1.8c-1.1,1.4-2.8,3.1-5.3,5.1c-0.7,0.5-1,1.5-0.6,2.3c0.3,0.8,1.2,1.3,2,1.3c0.5,0,2.9-0.2,5.6-0.8C105,37.9,103,39.9,100.2,42.5z"
          />
          <path
            fill="#fff"
            d="M32.1 65.1c.8-.1 1.4-.7 1.6-1.4.2-.8 0-1.6-.6-2.1-.5-.4-11.5-10.2-6.3-22.9 5.7 6 22.5 21.4 42.9 20.3.6 0 1.1-.3 1.5-.8.4-.5.5-1.1.3-1.7-.4-1.5-.6-3.1-.6-4.7 0-10.4 8.3-18.9 18.6-18.9 3.3 0 14.2-3 15.3-4.4-4 1.6-7.5 1.8-9.6 1.8-.5 0-1-.2-1.4-.6-3.5-3.6-8.2-5.6-13.2-5.6C70.3 24.1 62 32.5 62 42.9c0 1.6.2 3.2.6 4.7.1.6 0 1.2-.3 1.7-.4.5-.9.8-1.5.8-20.4 1.2-37.2-14.3-42.9-20.3-5.2 12.6 5.8 22.4 6.3 22.9.6.5.8 1.3.6 2.1-.2.8-.9 1.3-1.6 1.4-2.3.3-4.5-.3-6.3-1.1 1.1 8.5 6.6 12.5 10.4 14.3-.7-1.6-1.2-3.3-1.5-5.4C27.5 64.8 29.8 65.5 32.1 65.1zM40.1 83.4c.8-.4 1.3-1.2 1.2-2-.1-.9-.7-1.6-1.6-1.7-.3-.1-5-1.1-8.8-4.9-1.8.7-4 .8-5.9.7 2.5 5.1 6.5 7.7 9.8 8.9C36.5 84.3 38.5 84.1 40.1 83.4zM50.8 97.7c.5-.6.7-1.4.3-2.2-.3-.7-1-1.2-1.8-1.2 0 0 0 0 0 0-.1 0-5 0-9.7-3.2-6.8 5.9-15.5 7.4-21.8 7.6 8.5 4.2 17.8 5.2 24.7 5.2C45.5 102.4 48.4 100.4 50.8 97.7z"
          />
          <path
            fill="#444b54"
            d="M24.2 55.8c-.7 0-1.4-.2-2-.7-.5-.5-13.1-11.7-7.1-26.3.4-.9 1.2-1.6 2.2-1.8 1-.2 2 .1 2.7.9 16.7 17.5 32.3 19.5 39.3 19.4-.3-1.4-.4-2.8-.4-4.2 0-12 9.7-21.9 21.6-21.9 5.7 0 11 2.2 15 6.2 6.7-.4 10.7-3.7 10.8-3.7 1.3-1.1 3.2-.9 4.2.3 1.1 1.3.9 3.2-.3 4.2-.2.2-5.8 4.9-15 5.2-.1 0-.1 0-.2 0-1.3 0-2.6-.6-3.5-1.5-2.9-3-6.9-4.7-11.1-4.7C72 27.1 65 34.2 65 42.9c0 1.3.2 2.7.5 4 .4 1.5.1 3-.8 4.2-.9 1.2-2.3 2-3.8 2-18 1-33.2-9.9-41.4-17.4-.6 8.4 6.3 14.5 6.6 14.8 1.2 1.1 1.4 3 .3 4.2C25.9 55.4 25 55.8 24.2 55.8zM95.9 27.6C95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6 95.9 27.6zM99.6 47.1c-1 0-2-.5-2.6-1.5-.8-1.4-.3-3.3 1.1-4.1 5.4-3.1 11-10.2 11.1-10.3 1-1.3 2.9-1.5 4.2-.5 1.3 1 1.5 2.9.5 4.2-.3.3-6.4 8.1-12.8 11.8C100.6 46.9 100.1 47.1 99.6 47.1zM30.8 73.7c-.2 0-.4 0-.6-.1-.6-.1-14.4-3.2-16.3-18.1-.2-1.6 1-3.1 2.6-3.4 1.6-.2 3.1 1 3.4 2.6 1.4 10.7 11.2 12.9 11.6 13 1.6.3 2.7 1.9 2.3 3.6C33.4 72.8 32.2 73.7 30.8 73.7z"
          />
          <path
            fill="#444b54"
            d="M40.5 88.5L40.5 88.5c-1.7.1-3.8-.4-5.1-.8-4.1-1.2-9.8-4-13.1-11-.7-1.5-.1-3.3 1.4-4 1.5-.7 3.3-.1 4 1.4 3.8 7.9 11.9 8.3 12.6 8.4 1.7-.1 3.2 1.3 3.2 3C43.5 87.1 42.1 88.5 40.5 88.5zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM38.8 82.9C38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9 38.8 82.9zM40.6 95c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.4-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.5.4s.3.3.4.5.2.3.3.5c.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5s-.2.3-.4.5c-.1.1-.3.3-.5.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C41 94.9 40.8 95 40.6 95z"
          />
          <g>
            <path
              fill="#444b54"
              d="M17.9,101.7c-1.6,0-3-1.3-3-2.9c0-1.7,1.3-3,2.9-3.1c5.3-0.1,10.1-1.2,14.1-3.1c1.5-0.7,3.3-0.1,4,1.4c0.7,1.5,0.1,3.3-1.4,4C29.7,100.3,24.1,101.5,17.9,101.7C17.9,101.7,17.9,101.7,17.9,101.7z"
            />
          </g>
          <g>
            <path
              fill="#444b54"
              d="M42.3,106.9c-3,0-6.4-0.2-10-0.7c-1.6-0.2-2.8-1.8-2.5-3.4c0.2-1.6,1.8-2.8,3.4-2.5c10.8,1.6,20-0.3,20.1-0.3C99,89.1,96.7,46.1,96.6,44.3c-0.1-1.7,1.1-3.1,2.8-3.2c1.7-0.1,3.1,1.1,3.2,2.8c0.1,2,2.7,49.9-48,61.9C54.2,105.9,49.3,106.9,42.3,106.9z"
            />
          </g>
        </svg>
      </div>
      <div class="col" style="text-align: center">
        <h2 style="margin: 0px">COME RAGGIUNGERCI</h2>

        <div class="flex-grid" style="margin: 0">
          <div class="col" style="text-align: center">
            <img src="./assets/icons8-metropolitana-64.png" alt="" />
          </div>

          <div class="parag col" style="width: 80%; font-size: 15px; flex: 1">
            <h3
              style="text-transform: uppercase; margin: 0px; text-align: left"
            >
              METRO
            </h3>
            <p style="text-align: justify">
              Scendere alla fermata Marche (lato via Eritrea) continuare a piedi
              verso Collegno per 500m
            </p>
          </div>
        </div>
 
        <div class="flex-grid" style="padding: 19px">
          <div class="col" style="flex: 1; text-align: center">
            <img src="./assets/icons8-autobus-64.png" alt="" />
          </div>

          <div
            class="parag col"
            style="font-size: 15px; flex: 1; text-align: left; flex: 1"
          >
            <h3 style="text-transform: uppercase; margin: 0px">PULLMAN</h3>
            <p>
              Linea 33 o 36, scendere alla fermata Rieti Nord AUTO: Corso
              Francia, 385 - Torino
            </p>
          </div>
        </div>
      </div>
    </div>

     <div class="flex-grid">
      <div class="col" style="text-align: center; padding: 19px">
        <div>
          <h2 style="text-transform: uppercase; margin: 14px 0px 0px 0px">
            La nostra Tessera Punti
          </h2>
          <p class="parag">
            La Tua Farmacia ti premia! <br />
            La card viene rilasciata gratuitamente e senza alcun vincolo.
            Informativa sulla Privacy
          </p>
        </div>
      </div>

  
    </div>
  </div> -->
