import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardListComponent } from './card-list/card-list.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DetailComponent } from './detail/detail.component';
import { MessageFormComponent } from './message-form/message-form.component';
import { ArticalListComponent } from './artical-list/artical-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { MaterialModule } from './material/material.module';
import { ModaleComponent } from './modale/modale.component';
import { CommonModule } from '@angular/common';
import { ArticoloDetailComponent } from './articolo-detail/articolo-detail.component';
import { MainListComponent } from './main-list/main-list.component';
import { StylePaginatorDirective } from './style-paginator.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { TeamComponent } from './team/team.component';

@NgModule({
  declarations: [
    AppComponent,
    CardListComponent,
    HomePageComponent,
    DetailComponent,
    MessageFormComponent,
    ArticalListComponent,
    ModaleComponent,
    ArticoloDetailComponent,
    MainListComponent,
    StylePaginatorDirective,
    TeamComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    MatBadgeModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDJP9NU7XIo5rwSs8-wWymQa25iX4DXA80',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
