<div id="scroller" style="text-align: justify">
  <div style="font-weight: bold; display: flex; line-height: 1.5">
    <h1
      class="header-mod"
      [style]="
        isMobile()
          ? 'font-weight: bold; width: 300px; color: #8dc1d8;'
          : 'font-weight: bold; width: 500px; color: #8dc1d8'
      "
    >
      {{ data.title.replace("Autoanalisi ", "") }}
    </h1>
    <div style="width: 100%; text-align: end">
      <a
        style="margin: 0px 0px 0px 30%; cursor: pointer"
        class="icon-container"
        (click)="emailRedirect()"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="36px"
          viewBox="0 0 24 24"
          width="36px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
          />
        </svg>
      </a>
    </div>
  </div>
  <p *ngIf="data.text" style="min-width: 80%">
    {{ data.text }}
  </p>

  <div
    *ngIf="data.textHtml && data.title != 'Policy Privacy'"
    [innerHTML]="data.textHtml"
    [style]="
      isMobile()
        ? data.title == 'Tamponi'
          ? 'height: 220px; overflow: auto;overflow:auto;'
          : 'height: 147px; overflow: auto;overflow:auto;'
        : isTablet() || isDesktop()
        ? data.title == 'Altri servizi'
          ? 'height: 50px; overflow: auto;overflow:auto;'
          : data.title == 'Tamponi'
          ? 'height: 180px; overflow: auto;overflow:auto;'
          : 'height: 150px; overflow: auto;overflow:auto;'
        : 'height: 150px; overflow: auto;overflow:auto;'
    "
  ></div>

  <div
    id="privacy"
    *ngIf="data.textHtml && data.title == 'Policy Privacy'"
    [innerHTML]="data.textHtml"
    [style]="
      isMobile()
        ? data.title == 'Tamponi'
          ? 'overflow-y: scroll;height: 400px;'
          : 'overflow-y: scroll;height: 400px;'
        : isTablet() || isDesktop()
        ? data.title == 'Altri servizi'
          ? 'overflow-y: scroll;height: 400px;'
          : data.title == 'Tamponi'
          ? 'overflow-y: scroll;height: 400px;'
          : 'overflow-y: scroll;height: 400px;'
        : 'overflow-y: scroll;height: 400px;'
    "
  ></div>

  <div
    mat-dialog-content
    [style]="
      isMobile()
        ? 'height: 250px; overflow: auto;margin-top:1%;;'
        : isTablet()
        ? 'height: 270; overflow: auto;margin-top:1%;;'
        : ''
    "
    *ngIf="
      data.title != 'Laboratorio' &&
      data.title != 'ECG' &&
      data.title != 'Autoanalisi Creatinina' &&
      data.title != 'Policy Privacy' &&
      data.title != 'Altri servizi'
    "
  >
    <div
      [style]="
        data.title != 'Tamponi'
          ? 'height: 376px; margin-top: 3%; overflow: auto'
          : 'height: 125px; margin-top: 3%; overflow: auto'
      "
    >
      <div *ngFor="let l of data.array">
        <h3 class="titleMini">{{ l.titolo }}</h3>
        <div [innerHTML]="l.paragrafo"></div>
        <!-- <p class="description">{{ l.paragrafo }}</p> -->
      </div>
    </div>
  </div>
  <div
    mat-dialog-content
    [style]="
      isMobile()
        ? 'height: 350px; overflow: auto;margin-top:1%;;'
        : isTablet()
        ? 'height: 350px; overflow: auto;margin-top:1%;;'
        : ''
    "
    *ngIf="
      data.title === 'ECG' ||
      data.title === 'Altri servizi' ||
      data.title === 'Autoanalisi Creatinina'
    "
  >
    <div style="height: 500px; margin-top: 3%; overflow: auto">
      <div *ngFor="let l of data.array">
        <h3 class="titleMini">{{ l.titolo }}</h3>
        <div [innerHTML]="l.paragrafo"></div>
        <!-- <p class="description">{{ l.paragrafo }}</p> -->
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">Chiudi</button> -->
    <a
      style="margin-left: 97%"
      href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
      target="_blank"
    >
    </a>
  </div>
</div>
