import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  deviceInfo = null;
  email_1 = 'info@farmaciadonato.com';
  email_2 = 'laboratorio@farmaciadonato.com';
  email_3 = 'al3çgmail.com';
  cellulare = '0114032610';
  telefonoFisso = '0114032610';
  constructor() {}
  static isMobile() {
    let viewportWidth = window.innerWidth;
    const MOBILE_Width = 700;

    if (viewportWidth < MOBILE_Width) {
      return true;
    } else return false;
  }
  static isTablet() {
    let viewportWidth = window.innerWidth;
    const MOBILE_Width = 700;
    const TABLET_Width = 1600;

    if (viewportWidth > MOBILE_Width && viewportWidth < TABLET_Width) {
      return true;
    } else return false;
  }
  static isDesktop() {
    let viewportWidth = window.innerWidth;
    const TABLET_Width = 1600;

    if (viewportWidth > TABLET_Width) {
      return true;
    } else return false;
  }
  static getEmail_1() {
    let email_1 = 'info@farmaciadonato.com';

    return email_1;
  }
  static getEmail_2() {
    let email_2 = 'laboratorio@farmaciadonato.com';
    return email_2;
  }
  static getEmail_3() {
    let email_3 = 'al3çgmail.com';
    return email_3;
  }
  static getCellulare() {
    let cellulare = '0114032610';
    return cellulare;
  }

  static getTelefonoFisso() {
    let telefonoFisso = '0114032610';
    return telefonoFisso;
  }
  static genericSubject_EMAIL(title?) {
    let genericSubject_EMAIL_str = 'Farmacia donato: Richiesta generica';

    if (title === 'Tamponi') {
      genericSubject_EMAIL_str = 'Farmacia donato: Sezione tamponi';
    } else genericSubject_EMAIL_str = 'Farmacia donato: Richiesta generica';
    return genericSubject_EMAIL_str;
  }

  static genericBody_EMAIL(title?) {
    let genericBody_EMAIL_str =
      'Inserire il contenuto della richiesta di seguito';
    if (title === 'Tamponi') {
      genericBody_EMAIL_str =
        'Per prenotare un tampone è necessario condividere di seguito codice fiscale, numero e scadenza della tessera sanitaria e numero di telefono.';
    } else
      genericBody_EMAIL_str =
        'Inserire il contenuto della richiesta di seguito';
    return genericBody_EMAIL_str;
  }
}
