<!-- <ul class="cards">
  <li class="cards__item_half" style="display: inline">
    <div style="width: 100%; height: 400px; display: flex">
      <div class="card" style="width: 40%; margin: 6px">
        <div class="card__image card__image--autoanalisi"></div>
        <div class="card__content">
          <div class="card__title">Autoanalisi</div>

          <p class="card__text description">
            This is the shorthand for flex-grow, flex-shrink and flex-basis
            combined. The second and third parameters (flex-shrink and
            flex-basis) are optional. Default is 0 1 auto.
          </p>
          <div class="btn-container">
            <button
              (click)="openDialog('Autoanalisi')"
              class="btn btn--block card__btn columnBig"
            >
              Scopri di più
            </button>

            <a
              class="icon-container"
              href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
              target="_blank"
            >
            </a>
          </div>
        </div>
      </div>
      <div class="card" style="width: 60%; margin: 6px">
        <div class="card__image card__image--flowers"></div>
        <div class="card__content">
          <div class="card__title">I nostri articoli</div>
          <p class="card__text description">
            This defines the default size of an element before the remaining
            space is distributed. It can be a length (e.g. 20%, 5rem, etc.) or a
            keyword. The auto keyword means "look at my width or height
            property."
          </p>
          <button (click)="openDialog('j')" class="btn btn--block card__btn">
            Scopri di più
          </button>
        </div>
      </div>
    </div>
    <div class="cards__item" style="width: 100%; height: 400px">
      <div class="card" style="width: 60%; margin: 6px">
        <div class="card__image card__image--laboratorio"></div>
        <div class="card__content">
          <div class="card__title">Laboratorio</div>

          <p class="card__text description">
            This is the shorthand for flex-grow, flex-shrink and flex-basis
            combined. The second and third parameters (flex-shrink and
            flex-basis) are optional. Default is 0 1 auto.
          </p>
          <div class="btn-container">
            <button
              (click)="openDialog('Laboratorio')"
              class="btn btn--block card__btn columnBig"
            >
              Scopri di più
            </button>

            <a
              class="icon-container"
              href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to='ale0tusagmail.com&su='j'&body='Getile farmacia donato, '"
              target="_blank"
            >
            </a>
          </div>
        </div>
      </div>
      <div class="card" style="width: 40%; margin: 6px">
        <div class="card__image card__image--altriservizi"></div>
        <div class="card__content">
          <div class="card__title">Altri servizi</div>

          <p class="card__text description">
            This is the shorthand for flex-grow, flex-shrink and flex-basis
            combined. The second and third parameters (flex-shrink and
            flex-basis) are optional. Default is 0 1 auto.
          </p>
          <div class="btn-container">
            <button
              (click)="openDialog('Altri servizi')"
              class="btn btn--block card__btn columnBig"
            >
              Scopri di più
            </button>

            <a
              class="icon-container"
              href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </li>

  <li class="cards__item" style="display: inline">
    <div class="card" style="width: 100%; height: 43%; margin: 2%">
      <div class="card__image card__image--tesserapunti"></div>
      <div class="card__content">
        <div class="card__title">Tessera punti</div>

        <div class="btn-container">
          <button
            (click)="openDialog('Tessera punti')"
            class="btn btn--block card__btn columnBig"
          >
            Scopri di più
          </button>

          <a
            class="icon-container"
            href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g><rect fill="none" height="24" width="24" /></g>
              <g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" /></g>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div
      class="card"
      style="width: 100%; height: 43%; margin: 2%; margin-top: 20%"
    >
      <div class="card__image card__image--tirocionio"></div>
      <div class="card__content">
        <div class="card__title">Tirocinio</div>

        <div class="btn-container">
          <button
            (click)="openDialog('Tirocinio')"
            class="btn btn--block card__btn columnBig"
          >
            Scopri di più
          </button>

          <a
            class="icon-container"
            href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
            target="_blank"
          >
          </a>
        </div>
      </div>
    </div>
  </li>
</ul> -->

<h1 style="color: #8dc1d8; padding: 2% 0px 2% 3%;">COSA FACCIAMO</h1>
<mat-grid-list cols="11" rowHeight="6:4" gutterSize="10px" style="margin: 4%">
  <mat-grid-tile
    *ngFor="let tile of tiles"
    [colspan]="tile.cols"
    [rowspan]="tile.rows"
    [style.background]="tile.color"
  >
    <div class="a-box" style="width: 100%; height: 500px">
      <div class="img-container" style="width: 100%">
        <div class="img-inner" style="width: 100%">
          <div class="inner-skew{{ tile.radius }}" style="width: 100%">
            <img src="../assets/{{ tile.img }}" />
          </div>
        </div>
      </div>
      <div class="text-container" style="height: 300px">
        <h3>{{ tile.text }}</h3>
        <div style="text-align: justify; height: 78px">
          {{ tile.description }}
        </div>
        <div style="text-align: center; width: 100%">
          <button
            style="margin: auto; width: 60%"
            (click)="
              tile.redirect ? redirect(tile.redirect) : openDialog(tile.text)
            "
            mat-stroked-button
            color="primary"
          >
            Primary
          </button>

          <a
            style="margin: 0px 0px 0px 30%"
            class="icon-container"
            href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to={{
              tile.destinatari
            }}&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="36px"
              viewBox="0 0 24 24"
              width="36px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
