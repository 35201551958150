<h1
  style="
    padding: 1% 2%;
    color: whitesmoke;
    background: linear-gradient(45deg, #6b96ab 0 50%, #a6c0cc 50% 100%);
  "
  id="servizi"
>
  COSA FACCIAMO
</h1>
<mat-grid-list
  [cols]="isMobile() ? '12' : isTablet() ? '12' : '12'"
  rowHeight="80px"
  gutterSize="10px"
  style="margin: 4%"
>
  <mat-grid-tile
    [id]="tile.text"
    *ngFor="let tile of tiles"
    [colspan]="
      isMobile()
        ? tile.colMobile
        : isTablet()
        ? tile.colTablet
        : tile.colDesktop
    "
    [rowspan]="
      isMobile()
        ? tile.rowMobile
        : isTablet()
        ? tile.rowTablet
        : tile.rowDesktop
    "
    [style.background]="tile.color"
  >
    <!-- https://source.unsplash.com/random -->
    <div class="center" style="height: 100%">
      <div class="property-card" [style.height]="tile.height">
        <a
          [style]="
            tile.test === 'Cosmetica'
              ? 'margin:5% 0px 0px 0px;height:80%'
              : 'margin: 2% 0px 0px 0px;height:80%'
          "
        >
          <div class="property-image" style="height: 80%">
            <img
              src="./assets/{{ tile.img }}"
              [style]="
                tile.objfit || isMobile()
                  ? 'width: 100%;height: 96%;object-fit: cover;'
                  : 'width: 100%;height: 96%;'
              "
              alt=""
            />

            <div class="property-image-title">
              <!-- Optional <h5>Card Title</h5> If you want it, turn on the CSS also. -->
            </div>
          </div></a
        >
        <div class="property-description" style="height: 30%">
          <div style="width: 100; height: 100%; display: flex">
            <div
              [style]="
                tile.test === 'Cosmetica'
                  ? 'margin:5% 0px 0px 0px;flex-grow: 1;font-size: x-large;color: #6b96ab;'
                  : 'margin:2% 0px 0px 0px;flex-grow: 1;font-size: x-large;color: #6b96ab;'
              "
            >
              <h5
                class="title-main-item"
                [style]="
                  !isMobile()
                    ? 'font-size: 1.2em;margin: 8px 0px 0px 0px;'
                    : 'font-size: 0.9em;margin: 8px 0px 0px 0px;'
                "
              >
                {{ tile.text }}
              </h5>
            </div>
            <div style="flex-grow: 1; margin-top: 2%">
              <a
                href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to={{
                  tile.destinatari
                }}&su={{ tile.subject }}&body={{ tile.body }}'"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 0 24 24"
                  width="42px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z" opacity=".3" />
                  <path
                    d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"
                  />
                </svg>
              </a>
            </div>
            <div class="container-card-btn-email" style="text-align: center">
              <div
                *ngIf="tile.text != 'Cosmetica'"
                class="property-social-icons-left"
                mat-stroked-button
                [style]="'flex-grow: 1'"
                color="primary"
                (click)="
                  tile.redirect
                    ? redirectCard(tile.redirect)
                    : tile.text != 'Cosmetica'
                    ? openDialog(tile.text)
                    : ''
                "
              >
                <button class="custom-btn btn-5" style="width: 100%">
                  Scopri di più
                </button>
                <!-- <div class="property-social-icons">
                </div> -->
              </div>
            </div>
          </div>
          <!-- <p class="description-parag">{{ tile.description }}</p> -->
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <!-- <mat-grid-tile style="background-color: black;" [colspan]="12" [rowspan]="12">
 
    <mat-carousel
    style="background-color: white;" 
    style="width: 300px;height: 300px;"
      timings="250ms ease-in"
      [autoplay]="true"
      interval="5000"
      color="accent"
       proportion="25"
      slides="5"
      [loop]="true"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      orientation="ltr"
    >
      <mat-carousel-slide
      style="width: 300px;height: 300px;"        #matCarouselSlide
        *ngFor="let slide of slides; let i = index"
        [image]="slide.image"
        overlayColor="#00000040"
        [hideOverlay]="false"
      ></mat-carousel-slide>
    </mat-carousel>
  </mat-grid-tile> -->
</mat-grid-list>

<!-- https://source.unsplash.com/random -->
<!-- 
<div class="a-box" style="width: 100%; height: 500px">
  <div class="img-container" style="width: 100%">
    <div class="img-inner" style="width: 100%">
      <div class="inner-skew{{ tile.radius }}" style="width: 100%">
        <img src="../assets/{{tile.img}}" />
      </div>
    </div>
  </div>
  <div class="text-container" style="height: 300px">
    <h3>{{ tile.text }}</h3>
    <div style="text-align: justify; height: 78px">
      {{ tile.description }}
    </div>
    <div style="text-align: center; width: 100%">
      <button
        style="margin: auto; width: 60%"
        (click)="tile.redirect?redirect(tile.redirect):openDialog(tile.text)"
        mat-stroked-button
        color="primary"
      >
        Primary
      </button>

      <a
        style="margin: 0px 0px 0px 30%"
        class="icon-container"
        href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="36px"
          viewBox="0 0 24 24"
          width="36px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
          />
        </svg>
      </a>
    </div>
  </div>
</div>  -->
