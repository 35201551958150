<div class="form">
  <!-- <div class="title">Welcome</div>
  <div class="subtitle">Let's create your account!</div> -->
  <div class="input-container ic2">
    <input id="firstname" class="input" type="text" placeholder=" " />
    <div class="cut"></div>
    <label for="firstname" class="placeholder">Nome</label>
  </div>
  <div class="input-container ic2">
    <input id="lastname" class="input" type="text" placeholder=" " />
    <div class="cut"></div>
    <label for="lastname" class="placeholder">Cognome</label>
  </div>
  <div class="input-container ic2">
    <input id="email" class="input" type="text" placeholder=" " />
    <div class="cut cut-short"></div>
    <label for="email" class="placeholder">Email</label>
  </div>
  <div class="input-container ic2">
    <input id="email" class="input" type="text" placeholder=" " />
    <div class="cut cut-short"></div>
    <label for="email" class="placeholder">Telefono</label>
  </div>
  <div class="textArea-container ic2">
    <textarea id="w3review" class="textArea" name="w3review">
        At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
        </textarea
    >
    <div class="cut cut-short"></div>
    <label for="email" class="placeholder">Messaggio</label>
  </div>
  <button type="text" class="submit">submit</button>
</div>
