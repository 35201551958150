<div style="height: 20%; font-weight: bold; display: flex">
  <h1 style="height: 20%; font-weight: bold; width: 300px; color: #8dc1d8;">
    {{ data.text }}
  </h1>
  <div style="width: 100%; text-align: end">
    <a
      style="margin: 0px 0px 0px 30%"
      class="icon-container"
      href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="36px"
        viewBox="0 0 24 24"
        width="36px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 11h-4v4h-2v-4H7V9h4V5h2v4h4v2z"
        />
      </svg>
    </a>
    <a
      style="margin: 0px 0px 0px 30%"
      class="icon-container"
      href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="36px"
        viewBox="0 0 24 24"
        width="36px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
        />
      </svg>
    </a>
  </div>
</div>
<p style="min-width: 80%"></p>

<div mat-dialog-content style="height: 700px; overflow: auto">
  <div [innerHTML]="data.htmlText"></div>
</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">Chiudi</button> -->
  <a
    style="margin-left: 97%"
    href="https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=info@farmaciadonato.com&su=Farmacia donato: Richiesta generica&body=Inserire il contenuto della richiesta di seguito '"
    target="_blank"
  >
  </a>
</div>
